import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import { Container, Row, Column } from '../components/ui/layout/Grid'

export default ({ data }) => {
  const body = data.markdownRemark
  return (
    <>
      <Seo title={body.frontmatter.title} />
      <Container size="md">
        <Row>
          <Column>
            <h1>{body.frontmatter.title}</h1>
            <small>Last Updated: {body.frontmatter.lastUpdated}</small>
          </Column>
        </Row>
        <Row>
          <div dangerouslySetInnerHTML={{ __html: body.html }} />
        </Row>
      </Container>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        lastUpdated(formatString: "DD MMMM, YYYY")
      }
    }
  }
`
